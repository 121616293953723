import {useContext, useEffect, useRef, useState} from 'react';
import 'swiper/css';
import {
  DEFAULT_LAST_READING_CHAPTER,
  additionalBook,
  chapterId,
  content,
} from '../../constants/stringsAndFields';
import DataContext from '../../context/dataContext';
import {useTheme} from '../../theme/ThemeContext';
import {getIndexByChapterId, isNotNullNumber} from '../../utils/arrays';
import {copyToClipboard} from '../../utils/clipboard';
import {
  registerEventListeners,
  unRegisterEventListeners,
} from '../../utils/html/eventListeners';
import {generateChapterHtml} from '../../utils/html/html';
import {setChapterTitle} from '../../utils/navigation';
import {generateFavoriteQuote} from '../../utils/notes';
import {getBookmarkReading, saveLastReading} from '../../utils/storage';
import {getVerseElementId} from '../../utils/webview';
import Icon from '../ui-components/Icon';
import {UIConnectionStatus} from '../ui-components/UIConnectionStatus';
import {UIPressable} from '../ui-components/UIPressable';
import UIScreenReaderWrapper from '../ui-components/UIScreenReadingWrapper';
import ActionPickerModal from './ActionPickerModal';
import {AudioBottomSheet} from './AudioBottomSheet';
import EditNoteModal from './EditNoteModal';
import FootnoteModal from './FootnoteModal';
import {delay} from '../../utils/promise';
import {StyleSheet, Text, useWindowDimensions, View} from 'react-native';
import throttle from 'lodash/throttle';
import globalStyle from '../../theme/globalStyle';

const scrollCallback = throttle(
  (
    e,
    setButtonsVisible,
    offsetRef,
    buttonsVisible,
    navigation,
    existingStyle,
  ) => {
    const currentOffset = e.nativeEvent.contentOffset.y;
    const prevOffset = offsetRef.current;
    const direction = currentOffset > prevOffset ? 'down' : 'up';

    const threshold = 10;

    if (
      direction === 'up' &&
      Math.abs(currentOffset - prevOffset) > threshold
    ) {
      setButtonsVisible(true);
      navigation.setOptions({
        tabBarStyle: {...existingStyle, ...tabDefaultStyle},
      });
    } else if (direction === 'down') {
      setButtonsVisible(false);
      navigation.setOptions({
        tabBarStyle: {...existingStyle, ...tabHiddenStyle},
      });
    }

    offsetRef.current = currentOffset;
  },
  150,
);

const tabHiddenStyle = {
  display: 'none',
};

const tabDefaultStyle = {
  display: 'flex',
};

const useScrollHandler = navigation => {
  const offsetRef = useRef(0);
  const [buttonsVisible, setButtonsVisible] = useState(true);

  const {theme} = useTheme();
  const width = useWindowDimensions().width;
  const isDesktop = width > 1200;

  const existingStyle = {
    backgroundColor: theme.colors.navBackground,
    borderTopWidth: 0,
    height: theme.navHeight,
    paddingBottom: 5,
    paddingTop: 3,
    shadowColor: theme.colors.shadow,
    shadowOpacity: 0.4,
    shadowOffset: {width: 0, height: -1},
    shadowRadius: 10,
    elevation: 5,
    transition: 'transform 0.2s',
  };

  return {
    offset: offsetRef.current,
    buttonsVisible,
    scrollCallback: e => {
      if (isDesktop) return;
      scrollCallback(
        e,
        setButtonsVisible,
        offsetRef,
        buttonsVisible,
        navigation,
        existingStyle,
      );
    },
  };
};

const ReadingView = ({
  readingPlanData = null,
  verse = null,
  setVerses,
  isReadingPlan = false,
  setReadingVerse = () => {},
  chapterIndex = null,
  jumpToPageComplete = false,
  setJumpToPageComplete = () => {},
  restoredLastReading = false,      // @Samuel This is not used
  setRestoredLastReading = () => {},// @Samuel This is not used
  disableViewPager = false,         // @Samuel This is not used
  setShowSoundIcon,
  setBookmarkedReading,
  navigation,
  audioModalVisible,
  setAudioModalVisible,
}) => {
  const {
    getFootnote,
    saveFavoriteQuote,
    getBookByName,
    getNextChapter,
    getPreviousChapter,
    chapters,
    getVersesByChapter,
    showConnectionWarning,
  } = useContext(DataContext);
  const slideRef = useRef(null);
  const {theme, fontStyle, fontSize} = useTheme();
  const [footNoteModalVisible, setFootNoteModalVisible] = useState(false);
  const [noteEditModalVisible, setNoteEditModalVisible] = useState(false);
  const [actionPickerModalVisible, setActionPickerModalVisible] =
    useState(false);
  const [selectedText, setSelectedText] = useState('');
  const [selectedVerses, setSelectedVerses] = useState(null);
  const [htmlContent, setHtmlContent] = useState('');
  const [readingChapter, setReadingChapter] = useState('');
  const [footnote, setFootnote] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const [messageContent, setMessageContent] = useState('');

  const [currentIndex, setCurrentIndex] = useState(null);
  const isFirstChapter =
    readingChapter?.[chapterId] === DEFAULT_LAST_READING_CHAPTER;

  const global = globalStyle(theme);
  const styles = getStyles(theme);

  const width = useWindowDimensions().width;
  const isDesktop = width > 1200;
  const isDesktopLarge = width > 1600;

  useEffect(() => {
    if (!jumpToPageComplete && isNotNullNumber(chapterIndex) && chapters) {
      setReadingChapter(chapters[chapterIndex]);
      setJumpToPageComplete(true);
    }
  }, [jumpToPageComplete, chapterIndex]);

  useEffect(() => {
    if (readingPlanData) {
      setHtmlContent(readingPlanData[content]);
    }
  }, [readingPlanData]);

  useEffect(() => {
    if (readingChapter && chapters) {
      setHtmlContent(
        generateChapterHtml(readingChapter, false, fontStyle, fontSize, theme),
      );
      const chapterIdx = getIndexByChapterId(
        chapters,
        readingChapter[chapterId],
      );
      setChapterTitle(chapterIdx, chapters, navigation);
      setShowSoundIcon(!readingChapter?.[additionalBook]);
      getBookmarkReading().then(bookmark => {
        if (bookmark && bookmark === readingChapter?.[chapterId]) {
          setBookmarkedReading(true);
        } else {
          setBookmarkedReading(false);
        }
      });
      saveLastReading(readingChapter[chapterId]).catch(console.error);
      getVersesByChapter(readingChapter).then(v => {
        setVerses(v);
      });
      setCurrentIndex(chapterIdx);
    }
  }, [readingChapter, fontStyle, fontSize, theme]);

  useEffect(() => {
    if (selectedText && !actionPickerModalVisible) {
      setActionPickerModalVisible(true);
    }
  }, [selectedText]);

  useEffect(() => {
    if (slideRef.current && htmlContent) {
      registerEventListeners(interfaceExecuteCall);
      if (verse) {
        delay(300).then(() => {
          const verseElement = slideRef.current.querySelector(
            `#${getVerseElementId(verse)}`,
          );
          verseElement && verseElement.scrollIntoView();
          setReadingVerse(null);
        });
      }
    }
  }, [slideRef.current, htmlContent, verse]);

  useEffect(() => {
    if (actionPickerModalVisible || noteEditModalVisible) {
      unRegisterEventListeners();
    } else if (!actionPickerModalVisible && !noteEditModalVisible) {
      registerEventListeners(interfaceExecuteCall);
    }
  }, [actionPickerModalVisible, noteEditModalVisible]);

  const openPreviousChapter = async _ => {
    if (readingChapter) {
      const c = await getPreviousChapter(readingChapter[chapterId], false);
      if (c) {
        setReadingChapter(c);
      }
    }
  };

  const openNextChapter = async _ => {
    if (readingChapter) {
      const c = await getNextChapter(readingChapter[chapterId], false);
      if (c) {
        setReadingChapter(c);
      }
    }
  };

  const interfaceExecuteCall = (e, t, data) => {
    if (e === 'activateFootnote') {
      getFootnote(t).then(res => {
        setFootnote(res);
        setFootNoteModalVisible(true);
      });
    } else if (e === 'nextChapter') {
      openNextChapter().catch(console.error);
    } else if (e === 'selection') {
      setSelectedText(t);
      if (data) {
        setSelectedVerses(data);
      }
    }
  };

  const handleSelectionEvent = async webViewEvent => {
    const {key, selectedText, verses} = webViewEvent;
    if (key === 'copy') {
      copyToClipboard(selectedText);
      showTemporaryMessage('Citat je uspješno kopiran');
    } else if (key === 'favorite') {
      generateFavoriteQuote(
        readingChapter,
        selectedText,
        getBookByName,
        saveFavoriteQuote,
        verses,
      )
        .then(_ => {
          showTemporaryMessage('Citat je uspješno spremljen');
        })
        .catch(e => {
          console.log(e);
          showTemporaryMessage('Došlo je do pogreške pri spremanju citata');
        });
    } else if (key === 'note') {
      setNoteEditModalVisible(true);
    }
  };

  const showTemporaryMessage = message => {
    setMessageContent(message);
    setShowMessage(true);

    setTimeout(() => {
      setShowMessage(false);
    }, 3000);
  };

  const {scrollCallback, buttonsVisible} = useScrollHandler(navigation);

  const buttonLeftStyle = {
    transform: buttonsVisible ? 'translateX(0)' : 'translateX(-100%)',
    opacity: buttonsVisible ? 1 : 0,
    transition: 'transform 0.3s',
  };

  const buttonRightStyle = {
    transform: buttonsVisible ? 'translateX(0)' : 'translateX(100%)',
    opacity: buttonsVisible ? 1 : 0,
    transition: 'transform 0.3s',
  };

  const playerHiddenStyle = {
    transform: buttonsVisible ? 'translateY(0)' : 'translateY(55px)',
    transition: 'transform 0.3s',
  };

  const navLeftStyle = {
    left: isDesktopLarge ? 290 : isDesktop ? 250 : 20,
  };

  return (
    <>
      <>
        {!readingPlanData && !isFirstChapter && (
          <UIPressable
            onPress={openPreviousChapter}
            style={[
              styles.navButton,
              !isDesktop && buttonLeftStyle,
              navLeftStyle,
            ]}>
            <Icon name="arrow-left" size={20} color={theme.colors.text} />
          </UIPressable>
        )}

        {!readingPlanData && (
          <UIPressable
            onPress={openNextChapter}
            style={[
              styles.navButton,
              !isDesktop && buttonRightStyle,
              {right: isDesktopLarge ? 50 : 20},
            ]}>
            <Icon name="arrow-right" size={20} color={theme.colors.text} />
          </UIPressable>
        )}
      </>

      <UIScreenReaderWrapper
        scrollCallback={scrollCallback}
        onScroll={scrollCallback}>
        {showConnectionWarning && <UIConnectionStatus />}

        <View
          style={[
            isDesktop && styles.wrapperDesktop,
            {maxWidth: 1200, marginLeft: 'auto', marginRight: 'auto'},
          ]}>
          <div
            dangerouslySetInnerHTML={{__html: htmlContent}}
            style={{width: '100%'}}
            ref={slideRef}
          />
        </View>
        <FootnoteModal
          modalVisible={footNoteModalVisible}
          setModalVisible={setFootNoteModalVisible}
          footnoteContent={footnote}
        />
        <EditNoteModal
          modalVisible={noteEditModalVisible}
          setModalVisible={setNoteEditModalVisible}
          chapter={readingChapter}
          selectedVerses={selectedVerses}
          currentIndex={currentIndex}
        />
        <ActionPickerModal
          modalVisible={actionPickerModalVisible}
          setModalVisible={setActionPickerModalVisible}
          setSelectedText={setSelectedText}
          selectedText={selectedText}
          selectedVerses={selectedVerses}
          setSelectedVerses={setSelectedVerses}
          handleSelectionEvent={handleSelectionEvent}
          chapter={null}
          currentIndex={currentIndex}
        />
      </UIScreenReaderWrapper>

      {showMessage && (
        <View style={styles.messageBox}>
          <Text style={[global.textBold, styles.messageText]}>
            {messageContent}
          </Text>
        </View>
      )}

      <AudioBottomSheet
        modalVisible={audioModalVisible}
        setModalVisible={setAudioModalVisible}
        style={[!isReadingPlan && !isDesktop && playerHiddenStyle]}
        isReadingPlan={isReadingPlan}
      />
    </>
  );
};

const getStyles = theme =>
  StyleSheet.create({
    navButton: {
      position: 'fixed',
      top: '50%',
      marginTop: -25,
      zIndex: 1,
      width: 50,
      height: 50,
      backgroundColor: theme.colors.navBackground,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 50,
      shadowColor: theme.colors.shadow,
      shadowOpacity: 0.4,
      shadowOffset: {width: 0, height: 0},
      shadowRadius: 10,
      elevation: 5,
      transition: 'transform 0.3s',
    },

    wrapperDesktop: {
      paddingHorizontal: 40,
    },

    messageBox: {
      position: 'absolute',
      top: 20,
      left: '50%',
      transform: [{translateX: -140}],
      width: 280,
      padding: 10,
      backgroundColor: theme.colors.navBackground,
      borderRadius: 10,
      alignItems: 'center',
      shadowColor: theme.colors.shadow,
      shadowOpacity: 0.9,
      shadowOffset: {width: 0, height: 4},
      shadowRadius: 10,
      elevation: 5,
    },

    messageText: {
      color: theme.colors.primary,
    },
  });

export default ReadingView;
